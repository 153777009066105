import {Button, Card, Form, Input, Modal, Space, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useState, useRef } from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import { io } from 'socket.io-client'

import { useDispatch, useSelector } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartPie, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import FormNeuerAuftrag from '../components/forms/FormNeuerAuftrag'
import Anrufe from '../components/tiles/anrufe'
import TableAuftraege from '../components/tables/auftraege'
import Chatbox from '../components/tables/chatbox'
import CardsAuftraege from "../components/tiles/auftraege";
import Map, {NavigationControl, Marker} from 'react-map-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl'
import { debounce } from "lodash"

const { TabPane } = Tabs

const containerStyle = {
    width: '100%',
    height: '80vh'
}

const center = {
    lat: 52.5224962,
    lng: 13.4441354
}

const socket = io(process.env.NODE_ENV === 'production' ? 'https://kbcc.app.berliner-stadtmission.de' : 'http://localhost:8080')

export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([] as any)
    const [dataSource, setDataSource] = useState(null as any)
    const [defaultMessage, setDefaultMessage] = useState([] as any)
    const user = useSelector(state => state.user)
    const [chat, setChat] = useState(false as any)
    const [time, setTime] = useState(moment().format('HH:mm'))
    const [busse, setBusse] = useState([] as any)
    const [auftraege, setAuftraege] = useState([] as any)
    const [locations, setLocations] = useState([] as any)
    const [visible, setVisible] = useState(false)
    const map = useRef(null as any)

    const { pool }: any = useParams()

    useEffect(() => {
        socket.emit('room', pool, 'CC')
        // console.log(socket.id, user)
    }, [])

    useEffect(() => {
        fetchKB()
        fetchAuftrag()
        fetchOrte()
        fetchLocations()

        socket.on('auftrag', () => fetchAuftrag())
        socket.on('kbuser', (users) => {
            fetchKB()
        })
        socket.on('private message', ({ content, from }) => {
            setChat(from)
            setDefaultMessage(content)
        })

        /*setInterval(() => {
            setTime(moment().format('HH:mm'))
            fetchOrte()
            console.log('UPDATE')
        }, 60000)*/

    }, [])

    const fetchKB = () => {
        axios.get('/pool/schichten/' + pool)
            .then(data => {
                // console.log(data.data)
                setDataSource(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchOrte = () => {
        axios.get(`/schicht/${pool}/standorte`)
            .then(data => {
                setBusse(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchLocations = () => {
        axios.get(`/kaeltehilfe/locations/${pool}`)
            .then(data => {
                setLocations(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchAuftrag = () => {
        axios.get('/auftrag/pool/' + pool)
            .then(data => {
                setAuftraege(data.data)
            })
            .catch(error => console.log(error))
    }

    const debouncedSearch = debounce(async (criteria) => {
        await axios.get('/autocomplete/' + criteria.target.value)
            .then(data => {
                console.log(data.data)
            })
            .catch(error => console.log(error))
    }, 500)

    const sucheAutocomplete = async (val) => {
        debouncedSearch(val)
    }

    const setPrio = (val) => {
        if (val === 1) return 'ant-alert-success'
        if (val === 2) return 'ant-alert-warning'
        if (val === 3) return 'ant-alert-error'

        return 'ant-tag ant-tag-default'
    }

    return (
        <div className={'d-flex flex-column h-100'}>
            <div className={'border-bottom py-2 px-3 font-weight-bold bg-white text-uppercase d-flex justify-content-between'}>
                <span>{moment(pool).format('dddd, Do MMMM YYYY')}</span>
                <span>{time} Uhr</span>
            </div>
            <div className={'d-flex h-100 position-relative'}>
                {/*<div className={'suche-strasse'}>
                    <Input placeholder={'Adresse suchen'} onChange={sucheAutocomplete} bordered={false} />
                </div>*/}
                <div className={'anrufe-uebersicht pl-3 pt-3'}>
                    <h6 className={'font-weight-bold text-uppercase'}>Anrufe</h6>
                    <Anrufe pool={pool} socket={socket} />
                </div>
                <div className={'auftrag-uebersicht p-3 overflow-auto'}>
                    <div className={'d-flex justify-content-between'}>
                        <h6 className={'font-weight-bold text-uppercase'}>Aufträge</h6>
                        <Button onClick={() => setVisible(true)} className={'p-0 m-0'} type={'link'}>Neuer Auftrag</Button>
                    </div>
                    <div>
                        <CardsAuftraege socket={socket} auftraege={auftraege} map={map} />
                    </div>
                </div>
                <div className={'w-100'}>
                    <Map
                        ref={map}
                        mapLib={maplibregl as any}
                        initialViewState={{
                            longitude: 13.4441354,
                            latitude: 52.5224962,
                            zoom: 12
                        }}
                        style={{width: "100%", height: "100%"}}
                        mapStyle="https://tiles.locationiq.com/v3/streets/vector.json?key=pk.0f147952a41c555a5b70614039fd148b"
                    >

                        <NavigationControl position="top-right" />

                        {
                            auftraege && auftraege.map((item: any, key: number) => (
                                item.gemeldet.ort.geometry && <Marker longitude={item.gemeldet.ort.geometry.location.lng} latitude={item.gemeldet.ort.geometry.location.lat} key={'MARKER_AUFTRAG_' + key}>
                                    <div className={`p-2 ${setPrio(item?.gemeldet?.prio?.value)} font-weight-bold`} style={{borderRadius: '4px', overflow: 'hidden', opacity: item?.close ? 0.6 : 1}} onClick={() => {
                                        map.current.flyTo({center: [item.gemeldet.ort.geometry.location.lng, item.gemeldet.ort.geometry.location.lat], zoom: 16})
                                    }}>
                                        {moment(item.created_at).format('HH:mm')} / {item?.close ? moment(item?.close).format('HH:mm') : '--'} / {item?.zugewiesen?.name}
                                    </div>
                                </Marker>
                            ))
                        }

                        {
                            busse.length > 0 && busse.map((item: any, key: number) => (
                                item.standort && <Marker longitude={item.standort.coords.longitude} latitude={item.standort.coords.latitude} key={'MARKER_BUS_' + key}>
                                    <div className={`p-2 ant-alert-info font-weight-bold text-center`} style={{borderRadius: '4px', overflow: 'hidden', width: '80px'}} onClick={() => {
                                        map.current.flyTo({center: [item.standort.coords.longitude, item.standort.coords.latitude], zoom: 16})
                                    }}>
                                        {item.team.name}
                                    </div>
                                </Marker>
                            ))
                        }

                        {
                            locations.length > 0 && locations.map((item: any, key: number) => (

                                <Marker longitude={item.locationLocation.addressLongitude} latitude={item.locationLocation.addressLatitude} key={'MARKER_LOCAL_' + key}>
                                    <Tag color="purple" onClick={() => {
                                        map.current.flyTo({center: [item.locationLocation.addressLongitude, item.locationLocation.addressLatitude], zoom: 16})
                                    }}>
                                        {item.locationName} / {item?.locationGender?.genderName}
                                    </Tag>
                                </Marker>
                            ))
                        }

                    </Map>
                </div>
            </div>

            <Modal visible={visible} width={800} footer={null} destroyOnClose={true} onCancel={() => setVisible(false)}>
                <FormNeuerAuftrag socket={socket} />
            </Modal>

        </div>
    )
}
