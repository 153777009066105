import {Button, Card, DatePicker, Form, Input, message, Modal, Popconfirm, Space, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import PageWrapper from '../components/wrapper/page'

import axios from 'axios'
import FormNeuesTeam from "../components/forms/FormNeuesTeam";

import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    CloseCircleTwoTone,
  } from '@ant-design/icons'
  const { TabPane } = Tabs

export default () => {

    const history = useHistory()
    const [visible, setVisible] = useState({state: false, url: '', form: ''})
    const [teams, setTeams] = useState([] as any)
    const [times, setTimes] = useState([] as any)
    const [values, setValues] = useState(null as any)

    const getEhrenamt = () => {
        axios.get('/ehrenamt/list')
            .then(data => setTeams(data.data))
            .catch(error => console.log(error))
    }

    const getZeiten = () => {
        axios.get('/buchungen')
            .then(data => setTimes(data.data))
            .catch(error => console.log(error))
    }

    const getData = () => {
        setValues(null)
        setVisible({state: false, url: '', form: ''})
        getEhrenamt()
    }

    useEffect(() => {
        getEhrenamt()
    }, [])

    const change = (data, url, form) => {
        setValues(data)
        setVisible({state: true, url, form})
    }

    const deleteItem = (item: any, art) => {
        axios.delete(`/ehrenamt/${item._id}`)
            .then(data => {
                getEhrenamt()
            })
            .catch(error => console.log(error))
    }


    return (
        <PageWrapper title={'Einstellungen'}>
            <div className={''}>
                <div className={'row'}>
                    <div className={'col'}>

                        <Card 
                            title="Ehrenamtliche" 
                            extra={<Button onClick={() => setVisible({state: true, url: '/ehrenamt/neu', form: 'mitarbeiterProfil'})}>EA hinzufügen</Button>}
                        >
                            <Tabs type="card" onChange={(key) => key === '3' && getZeiten()}>
                                <TabPane tab="Ehrenamt mit AE" key="1">
                                    <Table pagination={false} dataSource={teams.filter((item: any) => item.AE)} rowKey={'_id'} columns={[
                                        {
                                            title: 'Name',
                                            key: 'name',
                                            render: (_) => `${_.Vorname} ${_.Nachname}`
                                        },
                                        {
                                            title: 'Telefon',
                                            dataIndex: 'Telefon',
                                            key: 'Telefon'
                                        },
                                        {
                                            title: 'Mail',
                                            dataIndex: 'Mail',
                                            key: 'Mail'
                                        },
                                        {
                                            title: 'Kostenstelle',
                                            dataIndex: 'Kostenstelle',
                                            key: 'Kostenstelle',
                                            render: (_) => <Tag>{_}</Tag>
                                        },
                                        {
                                            title: 'AE',
                                            dataIndex: 'AE',
                                            key: 'AE',
                                            render: (_) => _ ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone  twoToneColor="#eb2f96"  />
                                        },
                                        {
                                            title: 'Aktion',
                                            key: 'action',
                                            width: 300,
                                            render: (text, elem: any) => (
                                                <>
                                                    <Button type={'link'} onClick={() => change(elem, '/ehrenamt/' + elem._id, 'mitarbeiterProfil')}>Bearbeiten</Button>
                                                    <Link to={'/ehrenamt/' + elem._id} >Öffnen</Link>
                                                    <Popconfirm
                                                        title="EA löschen?"
                                                        onConfirm={() => deleteItem(elem, 'team')}
                                                        okText="Ja"
                                                        cancelText="Nein"
                                                    >
                                                        <Button type={'link'} danger>Löschen</Button>
                                                    </Popconfirm>
                                                </>
                                            ),
                                        },
                                    ]} />
                                </TabPane>
                                <TabPane tab="Rein Ehrenamtliche" key="2">
                                    <Table pagination={false} dataSource={teams.filter((item: any) => !item.AE)} rowKey={'_id'} columns={[
                                        {
                                            title: 'Name',
                                            key: 'name',
                                            render: (_) => `${_.Vorname} ${_.Nachname}`
                                        },
                                        {
                                            title: 'Telefon',
                                            dataIndex: 'Telefon',
                                            key: 'Telefon'
                                        },
                                        {
                                            title: 'Mail',
                                            dataIndex: 'Mail',
                                            key: 'Mail'
                                        },
                                        {
                                            title: 'Kostenstelle',
                                            dataIndex: 'Kostenstelle',
                                            key: 'Kostenstelle',
                                            render: (_) => <Tag>{_}</Tag>
                                        },
                                        {
                                            title: 'AE',
                                            dataIndex: 'AE',
                                            key: 'AE',
                                            render: (_) => _ ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone  twoToneColor="#eb2f96"  />
                                        },
                                        {
                                            title: 'Aktion',
                                            key: 'action',
                                            width: 300,
                                            render: (text, elem: any) => (
                                                <>
                                                    <Button type={'link'} onClick={() => change(elem, '/ehrenamt/' + elem._id, 'mitarbeiterProfil')}>Bearbeiten</Button>
                                                    <Link to={'/ehrenamt/' + elem._id} >Öffnen</Link>
                                                    <Popconfirm
                                                        title="EA löschen?"
                                                        onConfirm={() => deleteItem(elem, 'team')}
                                                        okText="Ja"
                                                        cancelText="Nein"
                                                    >
                                                        <Button type={'link'} danger>Löschen</Button>
                                                    </Popconfirm>
                                                </>
                                            ),
                                        },
                                    ]} />
                                </TabPane>
                                <TabPane tab="Zeiteinträge" key="3">
                                    <Table pagination={false} dataSource={times} rowKey={'_id'} columns={[
                                        {
                                            title: 'Zeitpunkt Eintrag',
                                            dataIndex: '',
                                            width: 200,
                                            render: (d) => <Tag color='blue'>{moment(d.created_at).format('DD.MM.YYYY HH:mm')}</Tag>
                                        },
                                        {
                                            title: 'Zeitraum',
                                            dataIndex: '',
                                            width: 200,
                                            render: (d) => <>
                                                <div>{moment(d.zeit_von).format('DD.MM.YYYY HH:mm')} -</div>
                                                <div>{moment(d.zeit_bis).format('DD.MM.YYYY HH:mm')}</div>
                                            </>
                                        },
                                        {
                                            title: 'Name',
                                            key: 'name',
                                            render: (_) => <Link to={'/ehrenamt/' + _?.client?._id}>{_?.client?.Vorname} {_?.client?.Nachname}</Link>
                                        },
                                    ]} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </div>
                </div>
               
            </div>
            <Modal
                title={`Neues Element hinzufügen`}
                visible={visible.state}
                onCancel={() => {
                    setValues(null)
                    setVisible({state: false, url: '', form: ''})
                }}
                footer={null}
                width={'50vw'}
                destroyOnClose={true}
            >
                <FormNeuesTeam form={visible.form} defaultValues={values} postUrl={visible.url} modalAction={() => getData()} />
            </Modal>
        </PageWrapper>
    )
}
