import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'

import Home from '../pages/Home'
import Ehrenamt_Detail_OTP from '../pages/Ehrenamt_Detail_OTP'
import Ehrenamt_Vereinbarung from '../pages/Ehrenamt_Vereinbarung'
import OTP_Home from '../pages/OTP_Home'
import OTP_PW from '../pages/OTP_PW'


export const AppRouterOTP = () => {

    return (
        <Router>
            <Route path="/" exact component={() => <Ehrenamt_Detail_OTP />} />
            <Route path="/vereinbarung/:id" exact component={() => <Ehrenamt_Vereinbarung />} />
        </Router>
    )
}
