import React, {useEffect, useState} from 'react'
import {Table, Button, DatePicker, Form, Space, Tag, Modal, Input, message, InputNumber, Upload, Alert} from 'antd'
import axios from 'axios'
import moment from 'moment'
import fileDownload from 'js-file-download'
import locale from 'antd/es/date-picker/locale/de_DE'
import { useParams } from 'react-router-dom'

const { RangePicker } = DatePicker


const AufwendungTable = ({client, tableData = null as any}) => {

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox')
    const [selected, setSelected] = useState([] as any)
    const [data, setData] = useState(tableData || [] as any)
    const [month, setMonth] = useState(moment())
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [modalData, setModalData] = useState(null as any)
    const [loading, setLoading] = useState(false)
    const [dokumente, setDokumente] = useState([])
    const [aForm] = Form.useForm()

    useEffect(() => {
        if (!tableData) getBuchungen()
        // getDokuments()
        // console.log(month)
    }, [month])

    const getBuchungen = () => {
        axios.get(`/buchungen/client/${client._id}/${month}`)
            .then(data => {
                setData(data.data)
                // console.log(data.data)
            })
            .catch(error => console.log(error))
    }

    const showModal = (d) => {
        setModalData(d)
        setIsModalVisible(true)
        aForm.resetFields()
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const exportCSV = () => {

        axios.post('/buchungen/pdf', {ehrenamt: client, aufwendungen: selected, month}, {responseType: 'blob'})
            .then(data => {
                fileDownload(data.data, `Aufwendungen_${client.Vorname}${client.Nachname}_${client.Nummer}.pdf`)
            })
            .catch(error => console.log(error))
    }

    const deleteBuchung = (id) => {
        axios.delete('/buchungen/delete/' + id)
            .then(data => getBuchungen())
            .catch(error => console.log(error))
    }

    const columns = [
        {
            title: 'Letzte Änderung',
            dataIndex: '',
            width: 200,
            render: (d) => <Tag color='blue'>{moment(d.created_at).format('DD.MM.YYYY HH:mm')}</Tag>
        },
        {
            title: 'Zeitraum',
            dataIndex: '',
            width: 200,
            render: (d) => <>
                <div>{moment(d.zeit_von).format('DD.MM.YYYY HH:mm')} -</div>
                <div>{moment(d.zeit_bis).format('DD.MM.YYYY HH:mm')}</div>
            </>
        },
        {
            title: 'Stunden',
            dataIndex: '',
            width: 100,
            render: (d) => `${moment.duration(moment(d.zeit_bis).diff(d.zeit_von)).asHours().toFixed(2).toString().replace('.', ',')}`
        },
        {
            title: 'Tätigkeit',
            width: 700,
            dataIndex: 'text',
        },
        {
            title: 'Stundensatz',
            dataIndex: 'stundensatz',
            render: (d) => `${d.toFixed(2).toString().replace('.', ',')}`
        },
        {
            render: (d) => (
                <>
                    <Button onClick={() => showModal(d)} className={'mr-2'}>Bearbeiten</Button>
                    <Button type="dashed" danger onClick={() => deleteBuchung(d._id)}>Löschen</Button>
                </>
            )
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            // console.log(selectedRows.map((item: any) => item._id))

            setSelected(selectedRows)
        }
    }

    const onChange = (date: any) => {
        date && setMonth(date.format())
    }

    const exportPDFVereinbarung = (mail = false) => {
        axios.post(`/ehrenamt/vereinbarung/pdf`, {ehrenamt: client, month, mail}, {responseType: 'blob'})
            .then(data => {
                if (!mail)
                    fileDownload(data.data, `Vereinbarung_${client.Vorname}${client.Nachname}_${client.Nummer}.pdf`)
                else
                    message.success('Erfolgreich versendet');
            })
            .catch(error => {
                message.error(JSON.stringify(error))
                console.log(error)
            })
    }

    const onFinish = (values: any) => {

        setLoading(true)

        const newData: any = {
            zeit_von: values.timerange[0].startOf('minute').format(),
            zeit_bis: values.timerange[1].startOf('minute').format(),
            text: values.text,
            stundensatz: values.stundensatz
        }

        if (modalData === null) {

            newData['client'] = client.Nummer

            axios.post('/buchungen/erfassen', newData)
                .then(data => {
                    setIsModalVisible(false)
                    setModalData(null)
                    setLoading(false)
                    getBuchungen()
                })
                .catch(error => {
                    message.error('Fehlgeschlagen')
                    console.log(error)
                })

        } else {
            axios.put('/buchungen/' + modalData._id, newData)
                .then(data => {
                    setIsModalVisible(false)
                    setModalData(null)
                    setLoading(false)
                    getBuchungen()
                })
                .catch(error => {
                    message.error('Fehlgeschlagen')
                    console.log(error)
                })
        }

    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }

    const formTailLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8, offset: 6 },
    }

    const props = {
        maxCount: 1,
        onChange(info) {
            if (info.file.status === 'uploading' && !loadingUpload) {
                setLoadingUpload(true)
                const formData = new FormData()
                formData.append('file', info.file.originFileObj)
                formData.append('Typ', 'Vereinbarung')
                formData.append('Monat', moment(month).format('YYYY-MM'))

                axios.post(`/upload/${client._id}`, formData).then(item => {

                    message.success(`${info.file.name} wurde erfolgreich hinzugefügt.`)
                    setLoadingUpload(false)

                }).catch(err => console.error(err))
            }
        }
    }

    const getDokuments = () => {
        axios.get(`/upload/${client._id}/${moment(month).format('YYYY-MM')}`).then((data) => {
            setDokumente(data.data.Dokumente)
        })
    }

    return (
        <>
            {
                !tableData ? (
                    <Space className={'mb-3'}>
                        <DatePicker picker="month" onChange={onChange} defaultValue={month} />
                        <Button onClick={() => showModal(null)}>Neuer Eintrag</Button>
                        <Button onClick={() => exportPDFVereinbarung()}>Monatsvereinbarung</Button>
                        <Button onClick={() => exportPDFVereinbarung(true)}>Vereinbarung zum Unterschreiben versenden</Button>
                        <Upload {...props}>
                            <Button>Vereinbarung hochladen</Button>
                        </Upload>
                    </Space>
                ) : (
                    <Space className={'mb-1 mt-5'}><h6 className={'text-uppercase font-weight-bold'}>{client?.Vorname} {client?.Nachname}</h6></Space>
                )
            }
            {
                dokumente && dokumente.length ? (
                    <Alert message="Monatsvereinbarung vorhanden" type="success" className={'mb-3'} />
                ) : (
                    <Alert message="Keine Vereinbarung vorhanden" type="error" className={'mb-3'} />
                )
            }
            <Table
                rowClassName={(record, index) =>
                    data.filter((time) => moment(time.zeit_von).isSame(moment(record.zeit_von), 'day')).length > 1 ? 'ant-tag-red' : '' ||
                    moment.duration(moment(record.zeit_bis).diff(record.zeit_von)).asHours() > 10 ? 'ant-tag-purple' : ''
                }
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                pagination={false}
                columns={columns}
                dataSource={data}
                rowKey={'_id'}
                footer={() => <Button type="primary" ghost onClick={() => exportCSV()}>PDF-Export</Button>}
            />

            <Modal title="Eintrag bearbeiten" visible={isModalVisible} footer={false} onCancel={handleCancel} destroyOnClose={true}>
                <Form onFinish={onFinish} form={aForm} {...formItemLayout}>
                        <Form.Item
                            label={'Deine Zeiten'}
                            name="timerange"
                            initialValue={[moment(modalData?.zeit_von), moment(modalData?.zeit_bis)]}
                        >
                            <RangePicker
                                locale={locale}
                                showTime={{ format: 'HH:mm' }}
                                format="DD.MM.YYYY HH:mm"
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Beschreibung'}
                            name="text"
                            initialValue={modalData?.text}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'Stundensatz'}
                            name="stundensatz"
                            initialValue={modalData?.stundensatz}
                        >
                            <InputNumber min={0} decimalSeparator={','} step={0.25} />
                        </Form.Item>
                        <Button loading={loading} type={'primary'} htmlType={'submit'}>Speichern</Button>
                </Form>
            </Modal>
        </>
    )
}

export default AufwendungTable
