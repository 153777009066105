import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Space, Statistic, Table, Tag, Switch} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom'

import { useDispatch } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import axios from 'axios'
import FormNeuerAuftrag from "../components/forms/FormNeuerAuftrag";
import FormNeuesTeam from "../components/forms/FormNeuesTeam";
import FormElement from "../components/forms/FormElement";
import {Editor} from '@tinymce/tinymce-react'
import { Content } from 'antd/lib/layout/layout'
import page from '../components/wrapper/page'
import fileDownload from 'js-file-download'
import AufwendungTable from '../components/tables/clientAufwendungen'
import { EmbedSignDocument } from '@documenso/embed-react'



export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [ehrenamt, setEA] = useState({} as any)

    const { id }: any = useParams()

    useEffect(() => {
        fetchEhrenamtlicher()
    }, [])

    const fetchEhrenamtlicher = () => {
        axios.get('/ehrenamt/' + id)
            .then(data => {
                setEA(data.data)
            })
            .catch(error => console.log(error))
    }

    const onChange = (val) => {
        axios.put('/ehrenamt/' + ehrenamt._id, {aktiv: val})
            .then(data => {
                message.success('Aktualisiert')
            })
            .catch(error => console.log(error))
    }


    return (
            <Content className='p-5'>
                <Row gutter={24} className={'mb-4'}>
                    <Col span={24}>
                        <Link to={'/ehrenamt'}>Zurück</Link>
                        <h3 className='py-2 m-0 font-weight-bold'>{ehrenamt.Nachname}, {ehrenamt.Vorname}</h3>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Card>
                            {
                                ehrenamt?._id && <AufwendungTable client={ehrenamt} />
                            }
                        </Card>
                    </Col>
                </Row>

            </Content>
    )
}
